import EmergencyContext from 'context/EmergencyContext';
import {useStarcarDateRangePickerState} from 'hooks/BPR/useStarcarDateRangePickerState';
import {useForm} from 'hooks/custom-react-hook-form';
import usePrevious from 'hooks/usePrevious';
import {useTranslation} from 'next-i18next';
import {useRouter} from 'next/router';
import {useContext, useEffect} from 'react';
import {DateValue} from 'react-aria';

import {yupResolver} from '@hookform/resolvers/yup';
import {CalendarDate, parseDateTime} from '@internationalized/date';

import {useGetApiV1GetBestpriceConfig, usePostApiV1CreateReservation} from 'lib/api/backend';

import {formatTime} from 'utils/date/formatDate';
import mergeErrors from 'utils/forms/mergeErrors';

import {ConsentContext} from 'components/section-components/ConsentManager';

import {BPRWidgetProps} from '../../components/section-components/bpr/BPRWidget/BPRWidget';
import yupSchema from '../../components/section-components/bpr/getSchema';
import {useStations} from './useStations';

export interface IUseBestpriceRechnerProps extends BPRWidgetProps {
  isWidget?: boolean;
  minValue?: DateValue;
  maxValue?: DateValue;
}

export const useBestPriceRechner = ({
  preferredVehicle: vehicle,
  presetStation,
  presetCategory,
  presetGroup,
  presetType,
  presetDistance,
  withSessionConfig = true,
  isWidget,
  maxRentalDuration,
  minValue,
  maxValue,
  origin = 'bestprice-normal',
}: IUseBestpriceRechnerProps) => {
  const {t} = useTranslation('bpr');
  const schema = yupSchema(t);
  const {
    push,
    query: {reservationId: pathReservationId},
  } = useRouter();

  const {isSuccess: isSuccessStations, stationsById, stations = []} = useStations(presetGroup);

  const {
    isLoading: isBestpriceLoading,
    isSuccess: isSuccessBestprice,
    data: bestpriceConfig,
  } = useGetApiV1GetBestpriceConfig(
    pathReservationId ? {reservationId: pathReservationId as string} : {},
  );

  const {
    isSuccess: isCreateReservationSuccess,
    data: {id: reservationId} = {},
    mutate: createReservationMutate,
    error: createReservationErrors,
  } = usePostApiV1CreateReservation({
    mutation: {onError: async err => err},
  });

  const requestErrors =
    (createReservationErrors?.response?.data && createReservationErrors?.response?.data?.errors) ||
    {};

  const {pushDataLayer} = useContext(ConsentContext);
  useEffect(() => {
    if (isCreateReservationSuccess && reservationId) {
      if (!presetGroup) {
        pushDataLayer({
          event: 'bestprice',
          station: fieldValues.station,
          category: fieldValues.category,
          kilometer: fieldValues.distance,
          affiliation: presetStation ? 'stationsliste' : 'header',
        });
        push(`/reservation/${reservationId}/step1`);
      } else {
        pushDataLayer({
          event: 'bestprice',
          station: fieldValues.station,
          category: fieldValues.category,
          kilometer: fieldValues.distance,
          affiliation: 'fahrzeugliste',
        });
        push(`/reservation/${reservationId}/step2`);
      }
    }
  }, [isCreateReservationSuccess, reservationId, presetGroup, push]);

  const formMethods = useForm({
    name: 'BestPriceRechner',
    defaultValues: {
      arrival: null,
      category: (presetCategory || t('bestpriceCategoryCar')) as string,
      departure: null,
      distance: presetDistance || '',
      departureTime: null,
      arrivalTime: null,
      group: presetGroup || '',
      station: presetStation || '',
      stationTextField: '',
      vehicle,
    },
    resolver: yupResolver(schema),
  });

  const {
    clearErrors,
    control,
    formState: {errors: formErrors, isDirty},
    handleSubmit,
    reset,
    setValue,
    watch,
  } = formMethods;

  const onSubmit = async stations => {
    const res = {
      ...stations,
      arrival: `${stations.arrival.year}-${stations.arrival.month}-${stations.arrival.day} ${stations.arrivalTime}`,
      category: presetCategory || stations.category,
      departure: `${stations.departure.year}-${stations.departure.month}-${stations.departure.day} ${stations.departureTime}`,
      distance: stations.distance ? Number(stations.distance) : '',
      group: presetGroup || stations.group,
      type: presetType || null,
      origin: origin,
      vehicle: vehicle || stations.vehicle,
    };
    createReservationMutate({data: res});
  };

  const errors = mergeErrors(formErrors as any, requestErrors);
  const fieldValues = watch();
  const {emergencyOnPointerDownHandler} = useContext(EmergencyContext);

  useEffect(() => {
    if ((withSessionConfig || !withSessionConfig) && isSuccessStations && presetStation) {
      setValue('stationTextField', stationsById[presetStation].description);
      setValue('station', presetStation);
    }
  }, [presetStation, isSuccessBestprice, isSuccessStations, withSessionConfig]);

  const previousBestPriceConfigDistance = usePrevious(bestpriceConfig?.distance);

  const starcarDateRangePickerState = useStarcarDateRangePickerState({
    props: {isWidget, minValue, maxValue, maxRentalDuration, defaultOpen: !!presetStation},
    formMethods,
    stationsById,
  });

  const {
    isDesktop,
    setCalendarOpen,
    resetDatepicker,
    resetFormValues,
    state: {setDateRange},
  } = starcarDateRangePickerState;
  useEffect(() => {
    if (
      bestpriceConfig &&
      isSuccessBestprice &&
      isSuccessStations &&
      !isDirty &&
      withSessionConfig
    ) {
      const {station, departure, arrival, category, distance} = bestpriceConfig;
      const dateRange = {start: null as CalendarDate, end: null as CalendarDate};
      console.log('BestPriceConfig', bestpriceConfig);
      if (station && departure && arrival && category && distance) {
        reset({
          station,
          stationTextField: stationsById[station]?.description,
          category,
          distance: `${distance}`,
        });
      }

      if (bestpriceConfig.departure) {
        setTimeout(() => {
          const departureDate = parseDateTime(bestpriceConfig.departure);
          dateRange.start = new CalendarDate(
            departureDate.year,
            departureDate.month,
            departureDate.day,
          );
          setValue('departure', dateRange.start, {
            shouldTouch: true,
            shouldDirty: false,
          });
          setValue(
            'departureTime',
            `${departureDate.hour.toString().padStart(2, '0')}:${departureDate.minute
              .toString()
              .padStart(2, '0')}`,
            {
              shouldTouch: true,
              shouldDirty: false,
            },
          );
        }, 50);
      }

      if (bestpriceConfig.arrival) {
        setTimeout(() => {
          const arrivalDate = new Date(bestpriceConfig.arrival);
          dateRange.end = new CalendarDate(
            arrivalDate.getFullYear(),
            arrivalDate.getMonth() + 1,
            arrivalDate.getDate(),
          );
          setValue('arrival', dateRange.end, {
            shouldTouch: true,
            shouldDirty: false,
          });
          setValue('arrivalTime', formatTime(arrivalDate), {
            shouldTouch: true,
            shouldDirty: false,
          });
        }, 50);
      }
    }

    if (
      bestpriceConfig?.distance &&
      previousBestPriceConfigDistance &&
      bestpriceConfig?.distance !== previousBestPriceConfigDistance
    ) {
      setTimeout(
        () =>
          setValue('distance', `${bestpriceConfig.distance}`, {
            shouldTouch: true,
            shouldDirty: true,
          }),
        30,
      );
    }
  }, [isSuccessBestprice, bestpriceConfig, isBestpriceLoading, isSuccessStations, isDirty]);
  // console.log({fieldValues})
  return {
    formMethods,
    onSubmit,
    emergencyOnPointerDownHandler,
    control,
    errors,
    fieldValues,
    stationsById,
    isDesktop,
    starcarDateRangePickerState,
    stations,
    presetStation,
  };
};
