import {useTranslation} from 'next-i18next';
import React, {useEffect} from 'react';
import {useRef} from 'react';
import {useFormContext} from 'react-hook-form';

import {Box, Theme, useMediaQuery} from '@mui/material';

import {Grid, Typography} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import ScrollBox from 'components/basic-components/ScrollBox';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

import StationCard from './StationCard';
import StationGmRadius from './StationGmRadius';
import StationListElement from './StationListElement';
import styles from './styles/stationSelectionFlyout.module.scss';

function StationSelectWrapper({
  gmRadiusStations,
  setGmRadiusStations,
  setOpen,
  setStation,
  setStationTextField,
  stations,
  value,
  isOpen,
  hoveredStation,
  setHoveredStation,
  valueIsStation,
  filteredStations,
  setFocusOninput,
  isWidget,
  showPopularStations,
}) {
  const {t} = useTranslation(['bpr', 'common']);
  // hide flyout so images dont get loaded on mobile STARCAR-1057
  const showFlyout = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const {watch} = useFormContext();

  const stationTextfieldValue = watch('stationTextField');

  useEffect(() => {
    if (!hoveredStation && filteredStations?.length && isOpen) {
      setHoveredStation(filteredStations[0]);
    }
    if (
      hoveredStation &&
      isOpen &&
      filteredStations?.length &&
      !filteredStations.filter(station => hoveredStation.id === station.id).length
    ) {
      setHoveredStation(filteredStations?.[0]);
    }
  }, [filteredStations, hoveredStation, isOpen]);

  useEffect(() => {
    if (!isOpen) {
      setHoveredStation(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (hoveredStation) {
      setStation(hoveredStation);
    } else {
      if (!valueIsStation) {
        setStation('');
      }
    }
  }, [hoveredStation]);

  useEffect(() => {
    setGmRadiusStations([]);
  }, [value]);
  const lastSelectedStation = useRef('');

  return (
    <ScrollBox className={`${isWidget ? styles.widgetFlyout : styles.flyout}`}>
      <CustomIcon name="close" onClick={() => setOpen(false)} className={styles.mobileCloseIcon} />
      <Box className={`${isWidget ? styles.widgetWrapper : styles.wrapper}`}>
        <Grid container columnSpacing={'2%'} id="station-list-overlay">
          <Grid
            xxs={12}
            lg={4}
            sx={{paddingTop: {xxs: '40px', lg: '20px'}, display: 'flex', flexDirection: 'column'}}
          >
            <Typography className={styles.stationsTitle}>
              {!stationTextfieldValue && showPopularStations ? t('popularStations') : t('stations', {ns: 'common'})}
            </Typography>
            <ScrollBox component="ul" className={styles.list}>
              {filteredStations?.map(station => {
                return (
                  <StationListElement
                    hoverHandler={setHoveredStation}
                    key={station.id}
                    isActive={station && hoveredStation && station.id === hoveredStation.id}
                    onClick={station => {
                      setStation(station);
                      lastSelectedStation.current = station.description;
                      setStationTextField(station.description);
                      setOpen(false);
                    }}
                    station={station}
                  />
                );
              }) || null}
              {(filteredStations?.length === 0 && (
                <Typography sx={{paddingLeft: '20px'}} component="li">
                  {t('noStationFound')}
                </Typography>
              )) ||
                null}
            </ScrollBox>
            {!gmRadiusStations?.length ? (
              <StationGmRadius
                setNearbyStations={setGmRadiusStations}
                stations={stations}
                textFieldValue={value}
                setFocusOninput={setFocusOninput}
              />
            ) : null}
          </Grid>
          <Grid xs={12} lg={8} sx={{display: {xs: 'none', md: 'block'}}}>
            {!showFlyout ? null : <StationCard station={hoveredStation} />}
          </Grid>
        </Grid>
      </Box>
    </ScrollBox>
  );
}
export default withErrorBoundary(StationSelectWrapper, 'StationSelectWrapper');
