import clsx from 'clsx';
import {useStarcarDateRangePickerStateContext} from 'hooks/BPR/useStarcarDateRangePickerState';
import {useTranslation} from 'next-i18next';
import {MouseEventHandler} from 'react';
import {Control, Controller} from 'react-hook-form';

import {InputAdornment} from '@mui/material';

import {TMergedErrors} from 'utils/forms/mergeErrors';

import {Box, TextField} from 'components/basic-components';
import {CustomIcon} from 'components/basic-components/CustomIcon/CustomIcon';
import styles from 'components/basic-components/Datepicker/styles.module.scss';
import FormErrors from 'components/basic-components/FormErrors';
import {withErrorBoundary} from 'components/error/ErrorBoundary';

const FormInputStation = ({
  control,
  errors,
  onFocus,
  onBlur,
  onClear,
  disabled,
  inputRef,
  isWidget,
}: {
  control: Control<any, any>;
  errors: TMergedErrors;
  onFocus: any;
  disabled?: boolean;
  onBlur?: Function;
  onClear?: MouseEventHandler<HTMLDivElement>;
  inputRef?: any;
  isWidget: boolean;
}) => {
  const [t] = useTranslation('bpr');
  const {isCalendarOpen} = useStarcarDateRangePickerStateContext();
  return (
    <>
      <Controller
        control={control}
        name="stationTextField"
        render={({field}) => {
          return (
            <TextField
              {...field}
              variant={isWidget ? 'standard' : 'filled'}
              inputRef={inputRef}
              disabled={disabled}
              autoComplete="off"
              className={errors?.station ? 'error' : ''}
              fullWidth
              placeholder={t('enterCityZip')}
              label={t('rentalStation')}
              onBlur={() => {
                onBlur();
                field.onBlur();
              }}
              onFocus={onFocus}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <CustomIcon
                      name="station"
                      style={{
                        height: '24px',
                        width: '24px',
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment:
                  field?.value?.length && onClear ? (
                    <InputAdornment position="end">
                      <Box
                        onClick={onClear}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          cursor: 'pointer',
                        }}
                        className={clsx({[styles.makeClickableInBackdrop]: isCalendarOpen})}
                        title={t('clearInput')}
                      >
                        <CustomIcon name="close" style={{height: '16px', width: '16px'}} />
                      </Box>
                    </InputAdornment>
                  ) : null,
              }}
            />
          );
        }}
      />
      <FormErrors errors={errors.station} />
    </>
  );
};

export default withErrorBoundary(FormInputStation, 'FormInputStation');
